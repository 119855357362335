<template>
  <div id="app" ref="btt">
    <Header />
    <router-view />
    <Footer @btt="backToTop" />
  </div>
</template>
<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
export default {
  components:{
    Header,
    Footer
  },
  data () {
    return {
      logo: '',
      bottom: {},
    }
  },
  created() {
    // 在页面加载时读取sessionStorage里的状态信息
    if (sessionStorage.getItem('store')) {
      this.$store.replaceState(
          Object.assign(
              {},
              this.$store.state,
              JSON.parse(sessionStorage.getItem('store'))
          )
      )
    }

    // 在页面刷新时将vuex里的信息保存到sessionStorage里
    // beforeunload事件在页面刷新时先触发
    window.addEventListener('beforeunload', () => {
      sessionStorage.setItem('store', JSON.stringify(this.$store.state))
    })
  },
  mounted() {
    this.$store.dispatch("setCompanyInfo");
    this.$store.dispatch("setFeedbackInfo");
    this.$store.dispatch("setCompanyVideo");
    this.$store.dispatch("setProductList");
    this.forbidZoom(); // dom挂载完成后，及禁止浏览器缩放
    this.forbidZoomClick();
    this.forbidZoomDoubleClick();
  },
  methods: {
    backToTop () {
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // 平滑滚动效果
      });
    },
    // 禁止缩放(浏览器中)
    forbidZoom() {
      document.addEventListener(
          'keydown',
          function (event) {
            if (
                (event.ctrlKey === true || event.metaKey === true) &&
                (event.keyCode === 61 ||
                    event.keyCode === 107 ||
                    event.keyCode === 173 ||
                    event.keyCode === 109 ||
                    event.keyCode === 187 ||
                    event.keyCode === 189)
            ) {
              event.preventDefault();
            }
          },
          false
      );

      document.addEventListener(
          'mousewheel',
          function (e) {
            e = e || window.event;
            // @ts-ignore
            if ((e.wheelDelta && event.ctrlKey) || e.detail) {
              event.preventDefault();
            }
          },
          {
            capture: false,
            passive: false
          }
      );
    },

    // 禁止双指放大（手机端）
    forbidZoomClick() {
      document.documentElement.addEventListener('touchstart', function (event) {
        if (event.touches.length > 1) {
          event.preventDefault();
        }
      }, false);
    },

    // 禁止双击屏幕放大（手机端）
    forbidZoomDoubleClick() {
      var lastTouchEnd = 0;

      document.documentElement.addEventListener('touchend', function (event) {
        var now = Date.now();
        if (now - lastTouchEnd <= 300) {
          event.preventDefault();
        }
        lastTouchEnd = now;
      }, false);
    }
  }
}
</script>
<style lang="scss">
#app {
  min-width: 1200px;
  //min-width: 1440px;
}

</style>
