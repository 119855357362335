import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './style/normaliz.css'
import 'animate.css'
import './style/animate.css';
import dialogPlugin from './dialogPlugin';
import videoPlugin from './videoPlugin';
import VueScrollTo from 'vue-scrollto';
import "video.js/dist/video-js.css";
import videojs from "video.js";
// 滚动动画 wow.js
import wow from './utils/wow'

Vue.prototype.videojs = videojs;

Vue.use(dialogPlugin);
Vue.use(videoPlugin);
Vue.use(VueScrollTo);

// Vue.prototype.$wow = new WOW({
//   boxClass: 'wow', // default
//   animateClass: 'animated', // default
//   offset: 0, // default
//   mobile: true, // default
//   live: false,
// });
Vue.prototype.$wow = wow


Vue.config.productionTip = false

//弹出框禁止滑动
Vue.prototype.$stopScroll = function () {
  let mo = function (e) { e.preventDefault() }
  document.body.style.overflow = 'hidden'
  document.addEventListener('touchmove', mo, false)// 禁止页面滑动
}

//弹出框可以滑动
Vue.prototype.$canScroll = function () {
  let mo = function (e) {
    e.preventDefault()
  }
  document.body.style.overflow = ''// 出现滚动条
  document.removeEventListener('touchmove', mo, false)
}

Vue.prototype.$toTop = function () {
  // chrome
  document.body.scrollTop = 0; // firefox
  document.documentElement.scrollTop = 0; // safari
  window.pageYOffset = 0;
}

document.querySelector('meta[name="keywords"]').setAttribute('content', "关键词");
document.querySelector('meta[name="description"]').setAttribute('content', "关键词");


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
