<template>
  <div v-if="visible" class="dialog">
    <div class="dialog-box">
      <div class="title">
        {{ $store.state.language == 0 ? '联系我们' : 'Contact Us' }}
        <span @click="close">×</span>
      </div>
      <div class="form">
        <div class="form-item">
          <div class="ipt">
            <p>{{ $store.state.language == 0 ? '姓名 *' : 'Name *' }}</p>
            <input type="text" v-model="name">
          </div>
          <div class="ipt">
            <p>{{ $store.state.language == 0 ? '电话 *' : 'Phone *' }}</p>
            <input type="text" v-model="phone">
          </div>
        </div>
        <div class="form-item">
          <div class="ipt">
            <p>{{ $store.state.language == 0 ? '公司名称 *' : 'Company Name *' }}</p>
            <input type="text" v-model="companyName">
          </div>
          <div class="ipt">
            <p>{{ $store.state.language == 0 ? '邮件 *' : 'Email *' }}</p>
            <input type="text" v-model="mailbox">
          </div>
        </div>
        <div class="form-item">
          <div class="ipt">
            <p>{{ $store.state.language == 0 ? '留言内容 *' : 'Content *' }}</p>
            <textarea name="content" id="content" v-model="content" ></textarea>
          </div>
        </div>
        <div class="form-submit">
          <button @click.prevent="submit">{{ $store.state.language == 0 ? '立即发送' : 'Submit' }}</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {postMessage} from "@/api";
export default {
  data () {
    return {
      visible: false,
      disableScroll: true,
      companyName: '',
      content: '',
      mailbox: '',
      name: '',
      phone: ''
    }
  },
  mounted() {
    console.log('show this : ', this.$store)
  },
  methods: {
    open() {
      this.$toTop();
      this.visible = true;
      this.$stopScroll();
    },
    close() {
      this.visible = false;
      this.$canScroll();
    },
    submit() {
      const that = this;
      const {companyName, content, mailbox, name, phone } = that;
      if (name.trim() == '') {
        alert(this.$store.state.language == 0 ? '用户名称不能为空' : 'Name cannot be empty.');
        return false;
      }
      if (phone.trim() == '') {
        alert(this.$store.state.language == 0 ? '联系电话不能为空' : 'Contact number cannot be empty.');
        return false;
      }
      if (companyName.trim() == '') {
        alert(this.$store.state.language == 0 ? '公司名称不能为空' : 'Company name cannot be empty.');
        return false;
      }
      if (mailbox.trim() == '') {
        alert(this.$store.state.language == 0 ? '电子邮箱不能为空' : 'Email cannot be empty.');
        return false;
      }
      if (content.trim() == '') {
        alert(this.$store.state.language == 0 ? '留言内容不能为空' : 'Content cannot be empty.');
        return false;
      }
      postMessage({
        companyName, content, mailbox, name, phone
      }).then(res => {
        if (res.code == 200) {
          alert(this.$store.state.language == 0 ? '留言提交成功，管理员会尽快处理您的留言信息。' : 'Leave a message successfully, the administrator will deal with your message as soon as possible.')
        } else {
          alert(this.$store.state.language == 0 ? '留言提交失败，请稍后重试！' : 'Failed to submit a message, please try again later!')
        }
        that.close();
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.dialog{
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0,0,0,.5);
  .dialog-box{
    width: 760px;
    height: 540px;
    padding-top: 20px;
    padding-bottom: 20px;
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    .title{
      width: 100%;
      margin-bottom: 20px;

      font-weight: 500;
      font-size: 18px;
      color: #222222;
      text-align: center;
      span{
        width: 30px;
        height: 30px;
        display: inline-block;
        margin-right: 20px;
        float: right;
        font-size: 40px;
        color: #000;
        margin-top: -30px;
        cursor: pointer;
      }
    }
    .form{
      width: 660px;
      height: 480px;
      //padding-left: 150px;
      .form-item{
        margin-bottom: 40px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .ipt{
          text-align: left;
          border-radius: 4px;
          p{
            margin: 0!important;
            padding-bottom: 10px;

            font-weight: 500;
            font-size: 20px;
            color: #222222;
            line-height: 29px;
          }
          input{
            width: 320px;
            height: 40px;
            background: #FBFBFB;
            border-radius: 0px 0px 0px 0px;
            border: 1px solid #E4E8EB;
          }
          textarea{
            width: 658px;
            height: 100px;
            background: #FBFBFB;
            border-radius: 0px 0px 0px 0px;
            border: 1px solid #E4E8EB;
            resize: none;
          }
        }
      }
      .form-submit{
        text-align: center;
        button{
          width: 162px;
          height: 54px;
          background: #2F318B;
          border: 0;
          border-radius: 2px 2px 2px 2px;

          font-weight: 400;
          font-size: 18px;
          color: #FFFFFF;
        }
      }
    }
  }
}
</style>
