import Vue from 'vue'
import Vuex from 'vuex'
import {getCompanyDetail, getProductDetail, getProducts, getVideos} from "@/api";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    language: 0,
    head_logo: require('../assets/index/top_logo@2x.png'), // 头部logo
    foot_logo: require('../assets/index/logo_bg@2x.png'), // 底部logo
    company_address: '', // 公司地址
    company_name: '', // 公司名称
    company_name_en: '', // 公司名称 - 英文
    company_abbreviation: '', // 公司简称
    company_focus: '', // 公司专注
    company_intro: '', // 公司简介
    company_introduction: '', // 公司介绍
    company_tel: '', // 公司电话
    company_mail: '', // 公司邮箱
    company_qr_code: require('../assets/index/code@2x.png'), // 售前二维码
    company_copyright: '', // 公司版权信息
    company_filing_info: '', // 公司备案信息
    company_picture: '', // 公司宏观照片
    company_feedback: '', // 公司客户反馈
    company_feedback_title: '', // 公司客户反馈的标题
    company_feedback_video_url: '', // 公司客户现场反馈视频地址
    company_video: '', // 公司介绍视频
    company_video_img: '', // 公司介绍视频封面图
    company_video_name: '', // 公司视频介绍标题
    product_list: [
      {
        id: '1',
        title: '蓝湿皮自动检测分选机BLD-3370',
        mic: 'Made In China',
        img: require("../assets/index/product_pic1@2x.png"),
        bg: require("../assets/index/product_bg1@2x.png"),
        list: [
          "检测分级准确，SATRA标准排版，分级精准直观",
          "效益高，人工分级准确率80%，机器可达95%",
          "数据可追溯，全新皮面瞬时标记技术",
          "统计便捷，批次总面积，批次内各等级总面积",
          "效率高，30秒一张完整牛皮，左右可单独分级",
          "分级标准可定制，8大类缺陷可选择性检测",
        ]
      },
      {
        id: '2',
        title: '成品皮缺陷识别机BLD-2530',
        mic: 'Made In China',
        img: require("../assets/index/product_pic2@2x.png"),
        bg: require("../assets/index/product_bg2@2x.png"),
        list: [
          "可以检测不同颜色的皮革",
          "兼容行业主流排料软件",
          "兼容行业主流切割设备",
          "可以检测不同种类的皮革，包括：牛皮、羊皮、猪皮、胎牛皮",
          "具备检测后排料功能，能将需要切割的版型排布在检测后的皮革轮廓内"
        ]
      },
    ], // 产品列表
    product_type: '1', // 当前详情页的产品ID
    product_detail: {}, // 产品详情
  },
  getters: {
    language: state => state.language,
    head_logo: state => state.head_logo,
    foot_logo: state => state.foot_logo,
    company_address: state => state.company_address,
    company_name: state => state.company_name,
    company_name_en: state => state.company_name_en,
    company_focus: state => state.company_focus,
    company_abbreviation: state => state.company_abbreviation,
    company_intro: state => state.company_intro,
    company_introduction: state => state.company_introduction,
    company_tel: state => state.company_tel,
    company_mail: state => state.company_mail,
    company_qr_code: state => state.company_qr_code,
    company_copyright: state => state.company_copyright,
    company_filing_info: state => state.company_filing_info,
    company_picture: state => state.company_picture,
    company_feedback: state => state.company_feedback,
    company_feedback_video_url: state => state.company_feedback_video_url,
    company_feedback_title: state => state.company_feedback_title,
    company_feedback_video_img: state => state.company_feedback_video_img,
    company_video: state => state.company_video,
    company_video_name: state => state.company_video_name,
    company_video_img: state => state.company_video_img,
    product_list: state => state.product_list,
    product_detail: state => state.product_detail,
  },
  mutations: {
    RESTORE_STATE(state, savedState) {
      Object.assign(state, savedState);
    },
    SET_LANGUAGE: (state, value) => {
      state.language = value;
      console.log(state.language, value)
    },
    SET_COMPANY_INFO: (state, value) => {
      state.head_logo = value.topLogoUrl;
      state.foot_logo = value.bottomLogoUrl;
      state.company_address = value.address;
      state.company_name = value.name;
      state.company_name_en = value.englishName;
      state.company_abbreviation = value.shortName;
      state.company_focus = value.focus;
      state.company_intro = value.brief;
      state.company_introduction = value.content;
      state.company_tel = value.phone;
      state.company_mail = value.mailbox;
      state.company_qr_code = value.saleCodeUrl;
      state.company_copyright = value.copyright;
      state.company_filing_info = value.filings;
      state.company_picture = value.imgUrl;
    },
    SET_FEEDBACK_INFO: (state, value) => {
      state.company_feedback = value.content;
      state.company_feedback_title = value.name;
      state.company_feedback_video_url = value.videoUrl;
      state.company_feedback_video_img = value.imgUrl;
    },
    SET_COMPANY_VIDEO: (state, value) => {
      state.company_video = value.videoUrl;
      state.company_video_name = value.name;
      state.company_video_img = value.imgUrl;
    },
    SET_PRODUCT_LIST: (state, value) => {
      state.product_list = value;
    },
    SET_PRODUCT_DETAIL: (state, value) => {
      state.product_type = value.id;
      value.checkDetail = value.checkDetail.split('&&&');
      state.product_detail = value;
    },
  },
  actions: {
    setLanguage({ commit }, value) {
      commit("SET_LANGUAGE", value);
    },
    // 获取公司信息
    setCompanyInfo({ commit }) {
      getCompanyDetail().then(res => {
        const data = res.data;
        commit("SET_COMPANY_INFO", data)
      })
    },
    // 获取公司反馈
    setFeedbackInfo({commit}){
      getVideos(2).then(res => {
        const data = res.data;
        commit("SET_FEEDBACK_INFO", data)
      })
    },
    // 获取公司视频
    setCompanyVideo({commit}){
      getVideos(0).then(res => {
        const data = res.data;
        commit("SET_COMPANY_VIDEO", data)
      })
    },
    // 获取公司产品列表
    setProductList({commit}){
      getProducts().then(res => {
        const data = res.data;
        let arr = [];
        data.forEach((item, index) => {
          let list = item.carouselContent.split("\n");
          arr.push({
            id: item.id,
            sort: item.sort,
            title: item.name,
            mic: 'Made In China',
            img: item.carouselUrl1,
            bg: item.carouselUrl2,
            list,
            imgUrl: item.imgUrl,
            videoUrl: item.videoUrl,
            special: item.special,
            specialTitle: item.specialTitle,
            advantageList: item.advantageList,
            specialList: item.specialList,
          })
        })
        commit("SET_PRODUCT_LIST", arr);
      })
    },
    // 获取公司产品详情
    setProductDetail({commit}, id){
      getProductDetail(id).then(res => {
        const data = res.data;
        data.specialList.forEach((item, index) => {
          item.active = index == 0
        })
        commit("SET_PRODUCT_DETAIL", data)
      })
    },
  },
  modules: {
  }
})
