import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      title: '专注皮革行业，专业解决方案 | 扬州哈工博视科技有限公司'
    },
    component: Home
  },
  {
    path: '/products',
    name: 'products',
    meta: {
      title: '专注皮革行业，专业解决方案 | 扬州哈工博视科技有限公司'
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Products.vue'),
  },
  {
    path: '/about',
    name: 'about',
    meta: {
      title: '专注皮革行业，专业解决方案 | 扬州哈工博视科技有限公司'
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/product',
    name: 'product',
    meta: {
      title: '专注皮革行业，专业解决方案 | 扬州哈工博视科技有限公司'
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Product.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // chrome
  document.body.scrollTop = 0; // firefox
  document.documentElement.scrollTop = 0; // safari
  window.pageYOffset = 0;
  /* 路由发生变化修改页面title */
  document.title = to.meta.title;
  next();
  //to 即将进入的路由
  //from 即将离开的路由
  //next 放行
});

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router
