<template>
  <div class="header-box">
    <div class="header">
      <div class="navbar">
        <div class="logo" @click="home"><img :src="logo" alt="扬州哈工博视"></div>
        <div class="nav">
          <router-link to="/" :class="[currentUrl == '/' ? 'active' : '']">{{ $store.state.language == 0 ? '首页' : 'Home' }}</router-link>
          <router-link to="/Product" :class="[currentUrl.indexOf('product') > -1 ? 'active' : '']">{{ $store.state.language == 0 ? '产品中心' : 'Products' }}</router-link>
          <router-link to="/about" :class="[currentUrl == '/about' ? 'active' : '']">{{ $store.state.language == 0 ? '关于我们' : 'About' }}</router-link>
        </div>
      </div>
      <div class="i18n">
        <div class="contact" @click="handleContact">{{$store.state.language == 0 ? '联系我们' : 'Contact Us'}}</div>
        <div class="language">
          <img class="gjh" src="../assets/index/language@2x.png" alt="国际化">
          <div class="zh-en" @click="handleLanguage">
            <span>{{language}}</span>
            <img class="down" src="../assets/index/down@2x.png" alt="国际化">
            <ul v-if="showLanguages" class="list">
              <li :class="[$store.state.language == 0 ? 'active' : '']" @click="() => choseLanguage(0)"><img src="../assets/chinese@2x.png" alt="国际化">中文</li>
              <li :class="[$store.state.language == 1 ? 'active' : '']" @click="() => choseLanguage(1)"><img src="../assets/english@2x.png" alt="国际化">English</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props:{
    logo:{
      type:String,
      default: require('../assets/index/top_logo@2x.png')
    }
  },
  data () {
    return {
      language: 0,
      showLanguages: false
    }
  },
  computed: {
    currentUrl() {
      return this.$route.path; // 获取当前路由的路径
    }
  },
  mounted() {
    let lan = localStorage.getItem('language') || 0;
    if (lan == 0) {
      this.language = '中文';
      localStorage.setItem('language', 0)
      this.$store.dispatch("setLanguage", 0);
    } else {
      this.language = 'EN';
      localStorage.setItem('language', 1)
      this.$store.dispatch("setLanguage", 1);
    }
  },
  methods: {
    home () {
      this.$router.push('/')
    },
    handleLanguage () {
      this.showLanguages = !this.showLanguages;
    },
    choseLanguage (type) {
      this.language = type == 0 ? '中文' : 'EN';
      localStorage.setItem('language', type)
      this.$store.dispatch("setLanguage", type);
    },
    handleContact() {
      this.$dialog.open();
    }
  }
}
</script>
<style lang="scss">
// 头部盒子
.header-box{
  //width: 100vw;
  height: 100px;
  box-shadow: 0px 1px 0px 0px #E1E1E1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 5px 0px 10px #b5b5b5;
  // 头部样式
  .header{
    //width: calc(100vw - 237px - 84px);
    width: 1200px;
    //width: 1440px;
    height: 100px;
    //padding-left: 237px;
    //padding-right: 84px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    // 左侧导航条及LOGO样式
    .navbar{
      display: flex;
      flex-direction: row;
      align-items: center;
      .logo{
        margin-right: 90px;
        cursor: pointer;
        img{
          width: 250px;
          height: 65px;
        }
      }
      .nav{
        a{
          margin-right: 64px;
          //margin-right: 80px;
          font-weight: 400;
          font-size: 20px;
          color: #222222;
          text-decoration: none;
          &:hover{
            color:#2F318B;
          }
          &.active{
            font-weight: 500;
            color:#2F318B;
          }
        }
      }
    }
    // 国际化
    .i18n{
      display: flex;
      flex-direction: row;
      align-items: center;
      .contact{
        width: 162px;
        height: 54px;
        margin-right: 80px;
        border: 1px solid #2F318B;
        border-radius: 4px;
        //
        font-weight: 400;
        font-size: 18px;
        color: #2F318B;
        display: flex;
        justify-content: center;
        align-items: center;
        letter-spacing: 2px;
        cursor: pointer;
      }
      .language{
        display: flex;
        flex-direction: row;
        align-items: center;
        .gjh{
          width: 18px;
          height: 18px;
          margin-right: 5px;
        }
        .zh-en{
          width: 60px;
          display: flex;
          flex-direction: row;
          align-items: center;
          position: relative;
          z-index: 0;
          span{
            display: inline-block;
            //
            font-weight: 400;
            font-size: 18px;
            color: #2F318B;
          }
          .down{
            width: 8px;
            height: 8px;
            margin-left: 10px;
          }
          .list{
            margin: 0;
            padding: 0;
            position: absolute;
            z-index: 999;
            left: -10px;
            top: 50px;
            list-style-type: none;
            width: 90px;
            height: 72px;
            background: #FFFFFF;
            box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.05);
            border-radius: 2px 2px 2px 2px;
            li {
              width: 90px;
              height: 34px;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              font-weight: 400;
              font-size: 16px;
              color: #2f318b;
              padding-left: 5px;
              img{
                width: 20px;
                height: 20px;
                margin-right: 5px;
              }
            }
            .active {
              background: #F3F3F3;
            }
          }
        }
      }
    }
  }
}
</style>
